import { WelkinEncounterTemplateNames } from '../welkin.types';

export type CdtMissedAppointment = {
   'cdtf-missed-appointment-date': string;
   'cdtf-encounter-id': string;
   'cdtf-encounter-type': WelkinEncounterTemplateNames;
   'cdtf-missed-appointment-fee-paid': MissedAppointmentFeePaid;
   'cdtf-transaction-id': string;
};

export enum MissedAppointmentFeePaid {
   Success = 'Success',
   Failed = 'Failed',
   Pending = 'Pending',
   None = 'None',

   // Encounter was set to e.g. Patient no show, but then switched to something else.
   Canceled = 'Canceled',
}
