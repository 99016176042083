export interface TimetapTimestapData {
   createdDate: number;
   createdUser: string | null;
   modifiedDate: number;
   modifiedUser: string | null;
}

export enum TimetapAppointmentType {
   TimeOff = 'TIME_OFF',
   Appointment = 'APPOINTMENT',
   Class = 'CLASS',
}

export enum TimetapAppointmentStatus {
   ReadOnly = 'READ_ONLY',
   Open = 'OPEN', // for appointment type it is uppercased
   OpenClass = 'Open', // this is for classes
   Cancelled = 'CANCELLED',
   NoShow = 'NO_SHOW',
   Completed = 'COMPLETED',
   Confirmed = 'CONFIRMED',
   CheckedIn = 'CHECKEDIN',
   PendingWaitlist = 'PENDING_WAITLIST',
   PendingConfirmation = 'PENDING_CONFIRMATION',
   Pending = 'PENDING',
   InProgress = 'INPROGRESS',
   Closed = 'CLOSED',
   Deleted = 'DELETED',
}

export enum TimetapReasonType {
   Service = 'SERVICE',
   Class = 'CLASS',
}

export enum TimetapStatus {
   Active = 'ACTIVE',
   Deactive = 'DEACTIVE',
}

export interface TimetapDate {
   day: number;
   month: number;
   year: number;
   dayOfWeek: number;
   dateId: null; // ??
   openSlotCount: number;
   date: number;
}

export interface TimetapTimezone {
   timeZoneCode: string;
   timeZoneDesc: string;
   timezoneId: number;
   sortOrder: number;
   visible: null; // ??
}
