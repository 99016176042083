// All the types used in OUR API

import { TimetapLocationReportParams } from './location.dto';
import { z } from 'zod';

// https://timetap.atlassian.net/wiki/spaces/TTAPI/pages/58753064/Locations
export type GetLocationsParamsDto = Pick<
   TimetapLocationReportParams,
   'locationGroupId' | 'pageNumber' | 'pageSize' | 'staffId'
>;

export type TimetapAppointmentCustomFieldDesc = {
   zoomUrl?: string | null;
};

export enum TimetapCustomFieldMode {
   Appointment = 'APPT',
   Client = 'CLIENT',
}

export type TimetapCustomField = {
   schedulerPreferenceFieldDefnId: number;
   businessId: number;
   code: 'UDF';
   mode: TimetapCustomFieldMode;
   label: string;
   value: string | null;
};

/**
 * Defined here:
 * https://timetap.atlassian.net/wiki/spaces/TTAPI/pages/506593283/Scheduler+Field
 */
export interface TimetapFieldDto {
   schedulerPreferenceFieldDefnId: number;
   businessId: number;
   code: 'UDF';
   dataType: 'AREA' | 'URL';
   mode: TimetapCustomFieldMode;
   label: string;
   hint: string | null;
   fieldValues: unknown;
   sortOrder: number;
   required: boolean;
   visible: boolean;
   userDefinedField: boolean;
   visibleOnScheduler: boolean;
   filterByService: boolean;
   serviceIds: unknown;
   active: boolean;
   tagName: string;
   oldFieldIds: unknown;
   // ...
   value: string | null;
}

export const timetapClientDateTimeSchema = z.object({
   startDate: z.string(),
   startTime: z.number(),
   endDate: z.string(),
   endTime: z.number(),
});

export type TimetapClientDateTime = z.infer<typeof timetapClientDateTimeSchema>;

export const timetapStaffDateTimeSchema = timetapClientDateTimeSchema.extend({
   startDateUtc: z.number(),
   endDateUtc: z.number(),
});

export type TimetapStaffDateTime = z.infer<typeof timetapStaffDateTimeSchema>;
