import _ from 'lodash';
import { InvalidJWTDto } from './invalid-jwt.dto';
import { TokenNotFoundErrorDto } from './token-not-found-error.dto';
import { ValidationErrorDto } from './validation-error.dto';
import { WelkinErrorCodes } from './welkin-error-codes.enum';

// Expecting data from axios response, an array is always returned
export function isWelkinValidationErrorPayload(
   data: unknown,
): data is ValidationErrorDto[] {
   if (Array.isArray(data)) {
      return (
         data.length === 1 &&
         _.isObject(data[0]) &&
         // eslint-disable-next-line @typescript-eslint/no-explicit-any
         (data[0] as any).errorCode === WelkinErrorCodes.FieldsValidationError
      );
   }
   return false;
}

export function isWelkinTokenNotFoundErrorPayload(
   data: unknown,
): data is TokenNotFoundErrorDto[] {
   if (Array.isArray(data)) {
      return (
         data.length === 1 &&
         _.isObject(data[0]) &&
         // eslint-disable-next-line @typescript-eslint/no-explicit-any
         (data[0] as any).errorCode === WelkinErrorCodes.TokenNotFound
      );
   }
   return false;
}

export function isWelkinInvalidJWTErrorPayload(
   data: unknown,
): data is InvalidJWTDto[] {
   if (Array.isArray(data)) {
      return (
         data.length === 1 &&
         _.isObject(data[0]) &&
         // eslint-disable-next-line @typescript-eslint/no-explicit-any
         (data[0] as any).errorCode === WelkinErrorCodes.TokenNotFound
      );
   }
   return false;
}
