export type Filter = {
   field: string;
   value: string;
   condition_type?: ConditionType;
};

export type Sort = {
   field: string;
   direction: string;
};

export enum SortDirection {
   ASC = 'asc',
   DESC = 'desc',
}

export type FilterGroup = Array<Filter>;

type SortOrders = Array<Sort>;

export enum ConditionType {
   Equal = 'eq',
   GreaterThan = 'gt',
   LessThan = 'lt',
   Null = 'null',
   NotEqual = 'neq',
   In = 'in',
   Like = 'like',
   Nin = 'nin',
}

export type MagentoSearchCriteria = {
   filter_groups: Array<FilterGroup>;
   sort_orders: Array<SortOrders>;
   page_size: number;
   current_page: number;
};
