import { EncounterType, State } from '@innerwell/prisma-app';
import { offsetPaginationSchema } from '../common';
import { z } from 'zod';

export type ServiceProviderLocation = {
   state: State | null;
   encounters: EncounterType[];
};

export type ServiceProviderLocationsResponse = ServiceProviderLocation[];

export const paginationSchema = offsetPaginationSchema.extend({
   where: z.object({
      clinicianId: z.string(),
      stateId: z.object({
         in: z.string().array(),
      }),
   }),
});
