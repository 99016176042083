export enum WelkinWebhookEventEntity {
   Patient = 'PATIENT',
   User = 'USER',
   EncounterDisposition = 'ENCOUNTER_DISPOSITION',
   Cdt = 'CDT',
   Assessment = 'ASSESSMENT',
   Encounter = 'ENCOUNTER',
   Document = 'DOCUMENT',
}

// TODO: misnomer, it should be WelkinEventType
export enum WelkinWebhookEventSubtype {
   PatientCreated = 'PATIENT_CREATED',
   PatientUpdated = 'PATIENT_UPDATED',
   UserDetailsUpdated = 'USER_DETAILS_UPDATED',
   EncounterDispositionUpdated = 'ENCOUNTER_DISPOSITION_UPDATED',

   CdtCreated = 'CDT_CREATED',
   CdtUpdated = 'CDT_UPDATED',
   CdtDeleted = 'CDT_DELETED',

   AssessmentCompleted = 'ASSESSMENT_COMPLETED',

   EncounterFinalized = 'ENCOUNTER_FINALIZED',

   DocumentUploaded = 'DOCUMENT_UPLOADED',
}
