import { DateTime } from 'luxon';
import { SchedulingError } from './SchedulingErrorBase.error';

export class WorkingHoursError extends SchedulingError {
   override name = 'scheduling.workingHours';

   constructor(
      private startDate: DateTime,
      private endDate: DateTime,
   ) {
      super(
         `Clinician not available in requested hours, ${startDate.toISO()} - ${endDate.toISO()}`,
      );
   }
}
