import { WelkinWebhookEventSubtype } from '../welkin-webhook.dto';
import { WelkinCdtEnum, WelkinMetaInfo } from '../welkin.types';
import { WelkinPatientResponseDto } from '../welkin-patient-response.dto';

export const AuditEntity = {
   Cdt: 'CDT',
   Patient: 'PATIENT',
} as const;

export type AuditEntityType = (typeof AuditEntity)[keyof typeof AuditEntity];

export type AuditCdtRecord<T> = {
   patientId: string;
   jsonBody: T;
   id: string;
   cdtName: WelkinCdtEnum;
   cdtId: string;
   version: number;
};

export type AuditPatientRecord = WelkinPatientResponseDto;

export type AuditResponseItem<T> = {
   eventEntity: AuditEntityType;
   url: string;
   subjectUsername: string;
   subjectUserId: string;
   subjectActor: string;
   subjectFullName: string;
   patientFullName: string;
   patientId: string;
   instance: string;
   eventSubtype: WelkinWebhookEventSubtype;
   sentAt: string;
   record: T;
};

export type AuditResponse<Record> = {
   data: AuditResponseItem<Record>[];
   metaInfo: WelkinMetaInfo;
};
