import { z } from 'zod';
import { booleanExtendedField } from '../common/zod.fields';
import { extendApi } from '@anatine/zod-openapi';

export const availableEncountersParamsSchema = z.object({
   forInsurance: extendApi(booleanExtendedField.optional(), {
      description: 'Whether patient is insured or not',
   }),
});

export type AvailableEncountersParams = z.infer<
   typeof availableEncountersParamsSchema
>;
