import type { CognitoIdentityProviderServiceException } from '@aws-sdk/client-cognito-identity-provider';
import { BaseError, BaseErrorResponse, ErrorTypes } from '../../errors';

export interface CognitoErrorResponse extends BaseErrorResponse {
   name: string;
   status: number;
   code: string;
   err: CognitoIdentityProviderServiceException;
}

export class CognitoError extends BaseError {
   private err: CognitoIdentityProviderServiceException;

   constructor(
      err: CognitoIdentityProviderServiceException,
      public method: string,
   ) {
      super(err.message);

      this.err = err;
      this.type = ErrorTypes.Cognito;
   }

   get status() {
      return this.err.$response ? this.err.$response.statusCode : 0;
   }

   // Used for example when passed to BadRequestException
   toJSON() {
      return {
         __baseError: true,
         type: ErrorTypes.Cognito,
         name: this.name,
         message: this.message,
         status: this.status,
         code: this.err.$fault,
         err: this.err,
      };
   }
}

export function isCognitoError(error: unknown): error is CognitoErrorResponse {
   return (error as CognitoErrorResponse)?.type === ErrorTypes.Cognito;
}
