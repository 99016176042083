import { FormsortFormTypes } from './formsort-form-types';
import { z } from 'zod';
import { FormsortFormData, Prisma } from '@innerwell/prisma-app';
import { booleanExtendedField, dateTimeField, uuidSchema } from '../common';

export const createFormSortDataSchemeDto = z.object({
   formType: z.nativeEnum(FormsortFormTypes),
});

export type CreateFormSortDataDto = z.infer<typeof createFormSortDataSchemeDto>;

export const formsortFormDataFilterSchema = z.object({
   page: z.coerce.number().min(1).default(1),
   pageSize: z.coerce.number().min(1).default(20),

   orderBy: z
      .record(z.enum(['createdAt']), z.nativeEnum(Prisma.SortOrder))
      .optional(),
   filters: z
      .object({
         cognitoSub: uuidSchema.optional(),
         formsortFormType: z.nativeEnum(FormsortFormTypes).optional(),
         responder: uuidSchema.optional(),
         finalized: booleanExtendedField.optional(),
         createdAt: z
            .object({
               from: dateTimeField,
               to: dateTimeField,
            })
            .optional(),
      })
      .optional(),
});

export type GetFormsortFormDataParamsDto = z.infer<
   typeof formsortFormDataFilterSchema
>;

export type FormsortFormDataDto = {
   data: FormsortFormData[];
   nextPage: number | null;
   prevPage: number | null;
};
