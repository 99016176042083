import { TimetapStatus } from './shared.types';
import { TimetapTimezoneDto } from './timezone.dto';
import { z } from 'zod';
import { intExtendedField } from '../common';

export const timetapStaffParamsSchema = z.object({
   locationId: intExtendedField.optional(),
});

export type TimetapStaffParamsDto = z.infer<typeof timetapStaffParamsSchema>;

export interface TimetapStaffResponseDto {
   professionalId: number;
   fullName: string;
   userName: string;
   email: string | null;
   phone: string | null;
   mobilePhone: string | null;
   active: boolean;
   acceptAppointments: boolean;
   businessId: number;
   imageUrl: string | null;
   locationIdAccessList: number[] | null;
   status: TimetapStatus;
   accountLocked: boolean;
   timeZone?: TimetapTimezoneDto;
}

export type TimetapStaffReportParams = {
   staffIdList?: number[];
};
