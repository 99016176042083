import { WelkinEncounterTemplateNames } from '../../welkin';
import { SchedulingError } from './SchedulingErrorBase.error';

export class MaxCountExceededError extends SchedulingError {
   override name = 'scheduling.maxCountExceeded';

   constructor(
      appointmentTemplate: WelkinEncounterTemplateNames,
      maxCount: number,
      // whether the rules are set to look up in a bank
      bank: boolean,
   ) {
      super(
         `Cannot schedule the appointment ${appointmentTemplate}, ${bank ? 'no available appointment in the appointment bank.' : `max allowed count of ${maxCount} exceeded`}`,
      );
   }
}
