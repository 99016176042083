import {
   NmiBillingAddress,
   NmiCard,
   NmiEventType,
   NmiMerchantDefinedFields,
   NmiPlan,
   NmiWebhookEvent,
} from '../nmi.types';

export interface SubscriptionData {
   subscription_id: string;
   subscription_type: string; // expecting "cc" (Credit Card)
   processor_id: string;
   next_charge_date: string; // format: 2022-02-01
   completed_payments: number;
   attempted_payments: number;
   remaining_payments: number;
   ponumber: string;
   order_id: string;
   order_description: string;
   shipping: string;
   tax: string;
   website: string;
   plan: NmiPlan;
   billing_address: NmiBillingAddress;
   card: NmiCard;
   merchant_defined_fields?: NmiMerchantDefinedFields;
}

// This covers all the subscription events: added, updated & deleted
export type SubscriptionRequestDto = NmiWebhookEvent<SubscriptionData>;

export function isSubscriptionRequest(
   value: NmiWebhookEvent<unknown>,
): value is SubscriptionRequestDto {
   return [
      NmiEventType.RecurringSubscriptionAdded,
      NmiEventType.RecurringSubscriptionDeleted,
      NmiEventType.RecurringSubscriptionUpdated,
   ].includes(value.event_type);
}
