export abstract class SchedulingError extends Error {
   __schedulingError = true;

   toJSON() {
      return {
         __baseError: true,
         __schedulingError: this.__schedulingError,
         name: this.name,
         message: this.message,
      };
   }
}
