import { AxiosError, AxiosResponse } from 'axios';
import { BaseError, ErrorTypes } from '../../errors';
import { getMessageFromAxiosError } from '../../errors/errors.utils';

// This is the actual error payload sent from the backend
export class WelkinError<T = unknown> extends BaseError {
   status: number | null;
   error: T | null;

   constructor(
      err: AxiosError<T>,
      public method: string,
   ) {
      super(getMessageFromAxiosError(err));

      this.type = ErrorTypes.Welkin;

      // If response is not set, it isn't Welkin error
      const response = err.response as AxiosResponse<T>;
      this.status = response.status;
      this.error = response.data;
   }

   toJSON() {
      return {
         __baseError: true,
         type: ErrorTypes.Welkin,
         name: this.name,
         message: this.message,
         status: this.status,
         method: this.method,
         error: this.error,
      };
   }
}
