import {
   TimetapReasonType,
   TimetapStatus,
   TimetapTimestapData,
} from './shared.types';
import { z } from 'zod';
import { intExtendedField } from '../common';

// @TODO: incomplete
// https://timetap.atlassian.net/wiki/spaces/TTAPI/pages/58753073/Services
export const timetapServiceParamsSchema = z.object({
   locationId: intExtendedField.optional(),
   reasonTypeList: z.string().optional(),
   searchTerm: z.string().optional(),
   staffId: intExtendedField.optional(),
});

export type TimetapServiceParamsDto = z.infer<
   typeof timetapServiceParamsSchema
>;

export interface TimetapServiceDto extends TimetapTimestapData {
   reasonId: number;
   professionalId: number | null;
   businessId: number;
   visitMinutes: number;
   reasonDesc: string;
   active: boolean;
   personal: boolean;
   sortWeight: number;
   status: TimetapStatus;
   reasonType: TimetapReasonType;
}
