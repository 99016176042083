import { TimetapCustomField, TimetapFieldDto } from './api.types';
import { TimetapClientResponseDto } from './client.types';
import { TimetapLocationResponseDto } from './location.dto';
import { TimetapServiceResponseDto } from './reason.types';
import { TimetapAppointmentStatus } from './shared.types';
import { TimetapStaffResponseDto } from './staff.types';
import { z } from 'zod';

type CreateAppointmentLocationParam = {
   locationId: number;
};

type CreateAppointmentStaffParam = {
   professionalId: number;
};

type CreateAppointmentReasonParam = {
   reasonId: number;
};

type CreateAppointmentClientParam = {
   clientId: number;
};

export type TimetapAppointmentCustomFieldData = {
   fieldValue: string | null;
   idx?: number | null;
};

export type TimetapCreateAppointmentParams = {
   status: TimetapAppointmentStatus;

   subStatus: TimetapAppointmentStatus;
   startDate?: string; // YYYY-MM-DD
   startTime?: number; // 900 for 9am
   endDate?: string;
   endTime?: number;
   clientStartDate?: string;
   clientStartTime?: number;
   clientEndDate?: string;
   clientEndTime?: number;

   // @IsString()
   // @IsOptional()
   // customField1?: string;

   // @IsString()
   // @IsOptional()
   // customField2?: string;

   // @IsString()
   // @IsOptional()
   // customField3?: string;

   customFieldData?: TimetapAppointmentCustomFieldData[] | null;
   customFieldDesc?: string | null;
   location: CreateAppointmentLocationParam;
   staff: CreateAppointmentStaffParam;
   reason: CreateAppointmentReasonParam;
   client?: CreateAppointmentClientParam | null;
   fields?: TimetapCustomField[] | null;
};

export const timetapCreateAppointmentParamsSchema = z.object({
   status: z.nativeEnum(TimetapAppointmentStatus),
   subStatus: z.nativeEnum(TimetapAppointmentStatus),
   startDate: z.string().optional(),
   startTime: z.number().optional(),
   endDate: z.string().optional(),
   endTime: z.number().optional(),
   clientStartDate: z.string().optional(),
   clientStartTime: z.number().optional(),
   clientEndDate: z.string().optional(),
   clientEndTime: z.number().optional(),
   customFieldData: z
      .array(
         z.object({
            fieldValue: z.string().nullable(),
            idx: z.number().nullable().optional(),
         }),
      )
      .nullable(),
   customFieldDesc: z.string().nullable().optional(),
   location: z.object({
      locationId: z.number(),
   }),
   staff: z.object({
      professionalId: z.number(),
   }),
   reason: z.object({
      reasonId: z.number(),
   }),
   client: z
      .object({
         clientId: z.number(),
      })
      .nullable()
      .optional(),
   fields: z.array(z.unknown()).nullable(),
});
//.passthrough();

export type TimetapCreateAppointmentParamsDto = z.infer<
   typeof timetapCreateAppointmentParamsSchema
>;

export const clientsFilterDtoSchema = z.object({
   filter: z.string(),
});

export type ClientFilterDto = z.infer<typeof clientsFilterDtoSchema>;

export const timetapCancelAppointmentParamsSchema = z.object({
   sendStaffEmail: z.boolean(),
   sendClientEmail: z.boolean(),
});

export type TimetapCancelAppointmentParamsDto = z.infer<
   typeof timetapCancelAppointmentParamsSchema
>;

export interface TimetapAppointmentResponseDto {
   calendarId: number;
   businessId: number;
   status: TimetapAppointmentStatus;
   subStatus: TimetapAppointmentStatus;
   date: string; // YYYY-MM-DD
   staffTimeText: string;
   reason: TimetapServiceResponseDto;
   client: TimetapClientResponseDto | null;
   staff: TimetapStaffResponseDto;
   location: TimetapLocationResponseDto;

   startDate: string;
   startTime: number;
   endDate: string;
   endTime: number;
   subject: string | null;
   clientStartDate: string;
   clientEndDate: string;
   clientStartTime: number;
   clientEndTime: number;
   note: string | null;
   createddate: string;
   createduser: string;
   modifieddate: string | null;
   modifieduser: string | null;
   dateCompleted: string | null;
   dateCheckedIn: string | null;
   sendConfirmationToClient: boolean;
   sendConfirmationToStaff: boolean;
   price: number | null;
   appointmentDateTimeClient: string;
   appointmentDateTimeStaff: string;

   startDateTimeUTC: number;
   endDateTimeUTC: number;
   clientStartDateTimeUTC: number;
   clientEndDateTimeUTC: number;
   clientStartDateTime: number;
   clientEndDateTime: number;

   customField1: string | null;
   customField2: string | null;
   customField3: string | null;

   customFieldDesc: string | null;

   customFieldData:
      | {
           customFieldDataId: number;
           customFieldId: number | null;
           calendarId: number | null;
           fieldValue: string | null;
           modifiedUser: null; // ??
           idx: number | null;
           waitListId: number | null;
           clientId: number | null;
           recurringAppointmentId: number | null;
        }[]
      | null;

   fields: TimetapFieldDto[] | null;
}

export interface TimetapAppointmentsReportWithCountResponseDto {
   appts: TimetapAppointmentResponseDto[];
   count: number;
}
