import { z } from 'zod';
import {
   MissedAppointmentFeePaid,
   WelkinEncounterTemplateNames,
} from '../welkin';

export const missedAppointmentsSchema = z.object({
   id: z.string().uuid(),
   appointmentDate: z.string().datetime(),
   encounterId: z.string().uuid(),
   encounterType: z.nativeEnum(WelkinEncounterTemplateNames),
   status: z.nativeEnum(MissedAppointmentFeePaid),
   transactionId: z.string().optional(),
});

export type MissedAppointmentsResponseDto = z.infer<
   typeof missedAppointmentsSchema
>;
