import { z } from 'zod';
import { canBuyFollowOnsResponseDtoSchema } from './can-buy-followons-response.dto';
import { InnerwellProduct } from '../products';

export const allowedFollowOnsResponseDtoSchema =
   canBuyFollowOnsResponseDtoSchema.extend({
      products: z.array(
         z.custom<InnerwellProduct>(() => {
            return true;
         }),
      ),
   });

export type AllowedFollowOnsResponseDto = z.infer<
   typeof allowedFollowOnsResponseDtoSchema
>;
