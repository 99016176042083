import { BaseError, ErrorTypes } from '../../errors';
import { ClaimMdError } from '@innerwell/api-claim-md-types';
import { EligibilityResponseErrorDto } from '../../insurance';

// This is the actual error payload sent from the backend
export class ClaimMdException extends BaseError {
   constructor(
      message: string,
      private response: EligibilityResponseErrorDto,
      // private errors: ClaimMdError[],
      // private serviceError: boolean,
   ) {
      super(message);

      this.type = ErrorTypes.ClaimMd;
   }

   getResponse() {
      return this.response;
   }

   getErrors() {
      return this.response.errors;
   }

   getServiceError() {
      return this.response.serviceError;
   }

   toJSON(): ClaimMdExceptionPayload {
      return {
         __baseError: true,
         type: ErrorTypes.ClaimMd,
         errors: this.response.errors,
         name: this.name,
         message: this.message,
         serviceError: this.response.serviceError,
      };
   }
}

export type ClaimMdExceptionPayload = {
   __baseError: boolean;
   type: ErrorTypes;
   errors: ClaimMdError[];
   name: string;
   message: string;
   serviceError: boolean;
};

export function isClaimMdExceptionPayload(
   data: unknown,
): data is ClaimMdExceptionPayload {
   return (
      typeof data === 'object' &&
      data !== null &&
      (data as ClaimMdExceptionPayload).__baseError === true &&
      (data as ClaimMdExceptionPayload).type === ErrorTypes.ClaimMd
   );
}
