import { z } from 'zod';

export enum ChatWebhookSourceEnum {
   welkin = 'welkin',
   sendbird = 'sendbird',
}

export const createSendbirdUserShema = z.object({
   user_id: z.string().min(1),
   nickname: z.string().min(1),
   profile_url: z.string().min(1),
   issue_access_token: z.boolean().optional(),
});
export type CreateSendbirdUserDto = z.infer<typeof createSendbirdUserShema>;

export const createSendbirdGroupChannelSchema = z.object({
   user_ids: z.array(z.string()),
   name: z.string().optional(),
   channel_url: z.string().optional(),
   custom_type: z.string().optional(),
});

export type CreateSendbirdGroupChannelDto = z.infer<
   typeof createSendbirdGroupChannelSchema
>;

export const inviteSendbirdUserToChannelSchema = z.object({
   user_ids: z.array(z.string().min(1)),
});

export type InviteSendbirdUserToChannelDto = z.infer<
   typeof inviteSendbirdUserToChannelSchema
>;

// This is used for initial validation only and can be improved.
export const chatWebhookSchema = z.record(z.unknown());

export type ChatWebhookDto = z.infer<typeof chatWebhookSchema>;

export const welkinChatWebhookRequestSchema = z.object({
   patientId: z.string().uuid(),
   patientUrl: z.string().url().optional(),
   tenantName: z.string().optional(),
   instanceName: z.string().optional(),
   message: z.string(),
});

export type WelkinChatWebhookRequestDto = z.infer<
   typeof welkinChatWebhookRequestSchema
>;

const senderSchema = z.object({
   nickname: z.string(),
   user_id: z.string().uuid(),
   profile_url: z.string().url(),
   metadata: z.unknown().optional(),
});

const messagePayloadSchema = z.object({
   custom_type: z.string().optional(),
   created_at: z.number().optional(),
   message: z.string(),
   data: z.string().optional(),
   message_id: z.number().optional(),
   translations: z.unknown().optional(),
});

// const messageEventsSchema = z.object({
//    update_unread_count: z.boolean().optional(),
//    send_push_notification: z.boolean().optional(),
//    update_last_message: z.boolean().optional(),
// });

const channelSchema = z.object({
   is_distinct: z.boolean().optional(),
   name: z.string().optional(),
   custom_type: z.string(),
   is_ephemeral: z.boolean().optional(),
   channel_url: z.string(),
   is_public: z.boolean().optional(),
   is_super: z.boolean().optional(),
   data: z.string().optional(),
   is_discoverable: z.boolean().optional(),
});

export const sendbirdChatWebhookRequestSchema = z.object({
   category: z.string().optional(),
   sender: senderSchema,
   silent: z.boolean().optional(),
   custom_type: z.string().optional(),
   mention_type: z.string().optional(),
   mentioned_users: z.array(z.string()).optional(),
   app_id: z.string().uuid().optional(),
   payload: messagePayloadSchema,
   sender_ip_addr: z.string().optional(),
   type: z.string().optional(),
   // message_events: messageEventsSchema.optional(),
   channel: channelSchema,
   sdk: z.string().optional(),
});

export type SendbirdChatWebhookRequestDto = z.infer<
   typeof sendbirdChatWebhookRequestSchema
>;

export const webhookPayloadSchema = z.union([
   sendbirdChatWebhookRequestSchema,
   welkinChatWebhookRequestSchema,
]);

export interface CreateUserResponse {
   user_id: string;
   nickname: string;
   profile_url: string;
   access_token: string;
}

export interface GetUserResponse extends CreateUserResponse {
   unread_message_count: number;
}

export interface CreateGroupChannelResponse {
   name: string;
   channel_url: string;
   cover_url: string;
   unread_message_count: number;
   custom_type: 'care_team' | 'member_support' | '';
   member_count: number;
   last_message?: {
      created_at: number;
   } | null;
}

export type GetGroupChannelResponse = CreateGroupChannelResponse;

export type SendbirdUserChannelsResponse = {
   channels: CreateGroupChannelResponse[];
};

export interface GetGroupChannelsResponse {
   channels: (GetGroupChannelResponse & {
      members?: {
         user_id: string;
         nickname: string;
         state: string;
      }[];
   })[];
   next: string | null;
   ts: number;
}
