import { Prisma } from '@innerwell/prisma-app';
import { z } from 'zod';
import { DefaultCdtData, WelkinCdtDbAction } from '../welkin';
import { intExtendedField, jsonSchema } from '../common';

export type FormsortAnswerValue = Prisma.JsonValue;

export interface CdtAdapter<T = DefaultCdtData> {
   handler: WelkinCdtDbAction;
   name: string;
   payload: T;
}

export const formsortAnswersBaseSchema = z.object({
   current_step_id: z.string().optional(),
   current_step_index: z.string().optional(),
   cognitoSub: z.string().optional(),
});

export type FormsortAnswersBaseDto = z.infer<typeof formsortAnswersBaseSchema>;

export const defaultFormsortAnswersSchema =
   formsortAnswersBaseSchema.catchall(jsonSchema);

export type DefaultFormsortAnswersDto = z.infer<
   typeof defaultFormsortAnswersSchema
>;

export const defaultFormsortDescriptionsSchema = z.record(
   z.union([z.string(), z.null()]),
);

export type DefaultFormsortDescriptionDto = z.infer<
   typeof defaultFormsortDescriptionsSchema
>;

export const patientSurveyAnswersSchema = defaultFormsortAnswersSchema.extend({
   preparation: z.string(),
   'recommend-scale': intExtendedField,
   'clinician-comments': z.string(),
   'digital-experience': z.string(),
   'additional-services': z.string(),
   'preparation-comments': z.string(),
   'psychiatric-clinician': z.string(),
   what_is_your_email_address: z.string(),
});

export type PatientSurveyAnswers = z.infer<typeof patientSurveyAnswersSchema>;

export const entheaIntakeAnswersSchema = defaultFormsortAnswersSchema.extend({
   'enthea-members-last-name': z.string(),
   'date-of-birth': z.string(),
   'phone-number': z.string(),
   state: z.string(),
   'member-id': z.string(),
   'enthea-members-first-name': z.string(),
   'last-name': z.string(),
   supported_states: z.string(),
   email: z.string(),
   'first-name': z.string(),
});

export type EntheaIntakeAnswers = z.infer<typeof entheaIntakeAnswersSchema>;

const sharedPayloadShema = z.object({
   responder_uuid: z.string(),
   variant_label: z.string(),
   variant_uuid: z.string(),
   flow_label: z.string().optional(),
   created_at: z.string().optional(),
});

export const formsortWebhookRequestSchema = sharedPayloadShema.extend({
   answers: defaultFormsortAnswersSchema,
   descriptions: defaultFormsortDescriptionsSchema,
   finalized: z.boolean(),
});

export type FormsortWebhookRequestDto = z.infer<
   typeof formsortWebhookRequestSchema
>;
