import { DateTime } from 'luxon';
import { SchedulingError } from './SchedulingErrorBase.error';

export class TimeSlotOccupiedError extends SchedulingError {
   override name = 'scheduling.timeSlotOccupied';

   constructor(from: DateTime, to: DateTime) {
      super(`Selected time slot is occupied, ${from.toISO()} - ${to.toISO()}`);
   }
}
