import { AxiosError } from 'axios';
import _ from 'lodash';

export function getMessageFromAxiosError(err: AxiosError) {
   if (err.response && _.isObject(err.response.data)) {
      const data = err.response.data;
      if (Array.isArray(data)) {
         return (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (_.isObject(data[0]) && (data[0] as any).message) ?? err.message
         );
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (data as any).message ?? err.message;
   }
   return err.message;
}
