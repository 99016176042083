import _ from 'lodash';
import { ErrorTypes } from './error-types';

export interface ValidationError {
   name: ErrorTypes.ValidationError;
   // error: string;
   statusCode: number;
   // message: ValidationErrorItem[];
   message: string;
}

export function isValidationError(data: unknown): data is ValidationError {
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   return _.isObject(data) && (data as any).name === ErrorTypes.ValidationError;
}
