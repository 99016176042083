import { AxiosError } from 'axios';
import { BaseError, ErrorTypes } from '../../errors';
import { getMessageFromAxiosError } from '../../errors/errors.utils';

export class TimetapError<T = unknown> extends BaseError {
   constructor(
      err: AxiosError<T>,
      public method: string,
   ) {
      super(getMessageFromAxiosError(err));
      this.type = ErrorTypes.Timetap;
   }

   toJSON() {
      return {
         __baseError: true,
         type: this.type,
         name: this.name,
         message: this.message,
         method: this.method,
      };
   }
}
