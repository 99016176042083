import _ from 'lodash';
import { ReasonCode, WarningReasonCodes } from './precision.types';

const reasonCodeMap = _.transform(
   WarningReasonCodes,
   (result, value, key) => {
      result[value] = key;
   },
   {} as Record<ReasonCode, string>,
);

export function getWarningText(reasonCode: ReasonCode) {
   return `${reasonCodeMap[reasonCode] ?? 'Unknown error'} [${reasonCode}]`;
}
