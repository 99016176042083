import {
   TimetapAppointmentStatus,
   TimetapAppointmentType,
} from './shared.types';
import { z } from 'zod';
import {
   arrayOfNumbersField,
   arrayOfStringsField,
   booleanExtendedField,
   intExtendedField,
} from '../common';

export type TimetapCalendarParams = {
   staffIdList: string[];
   startDate?: string;
   endDate: string;
   locationIdList?: number[];
   serviceIdList?: number[];
   statusList?: string[];
   showTimeOff?: boolean;
   showPaid?: number;
};

export const timetapCalendarParamsSchema = z.object({
   staffIdList: arrayOfStringsField,
   startDate: z.string().optional(), //dateField or dateTimeField?
   endDate: z.string(),
   locationIdList: arrayOfNumbersField.optional(),
   serviceIdList: arrayOfNumbersField.optional(),
   statusList: arrayOfNumbersField.optional(),
   showTimeOff: booleanExtendedField.optional(),
   showPaid: intExtendedField.optional(),
});

export type TimetapCalendarParamsDto = z.infer<
   typeof timetapCalendarParamsSchema
>;

export interface TimetapCalendarRangeEventDto {
   id: number;
   title: string;
   start: string;
   end: string;
   startDateTimeUTC: string;
   endDateTimeUTC: string;
   allDay: boolean;
   color: string;
   appointmentType: TimetapAppointmentType;
   status: TimetapAppointmentStatus;
   subStatus: TimetapAppointmentStatus;
   recurringAppointmentId: number | null;
   attendees: string[] | null;
   paid: boolean;
   uninvoiced: boolean;
}
