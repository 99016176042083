export enum CognitoUserStatus {
   ARCHIVED = 'ARCHIVED',
   COMPROMISED = 'COMPROMISED',
   CONFIRMED = 'CONFIRMED',
   FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD',
   RESET_REQUIRED = 'RESET_REQUIRED',
   UNCONFIRMED = 'UNCONFIRMED',
   UNKNOWN = 'UNKNOWN',
}

export type CognitoUserInfo = {
   email?: string;
   userId?: string; //cognito sub
   welkinUserId?: string;
   formsortUUID?: string;
   accountStatus?: boolean;
   confirmationStatus?: CognitoUserStatus;
   createdAt?: Date;
   updatedAt?: Date;
};
