import { WelkinPatientProgramResponseDto } from './welkin-patient-program-response.dto';
import { WelkinRole, WelkinTimestamps } from './welkin.types';

export interface PatientWelkinTerritory {
   name: string;
   territories: string[];
}

export interface PatientCareTeam {
   id: string;
   username: string;
   firstName: string;
   lastName: string;
   email: string;
   roles: { name: WelkinRole; primaryRole: boolean }[];
   pointOfContact: boolean;
   enabled: true;
}

export const WELKIN_GENDER_OPTIONS = {
   Male: 'MALE',
   Female: 'FEMALE',
   Other: 'OTHER',
   Unknown: 'UNKNOWN',
} as const;

export type WelkinGender =
   (typeof WELKIN_GENDER_OPTIONS)[keyof typeof WELKIN_GENDER_OPTIONS];

export interface WelkinPatientResponseDto extends WelkinTimestamps {
   id: string;
   externalGuid: string | null;
   externalId: string | null;
   mrn: string | null;
   nric: string | null;

   firstName: string;
   lastName: string;
   middleName: string | null;
   birthDate: string | null;
   email: string;
   secondaryEmail: string | null;
   gender: WelkinGender;
   maritalStatus: string;
   primaryLanguage: string;
   secondaryLanguage: string | null;
   phone: string;
   primaryPhoneCapabilities: string[];
   secondaryPhone: string | null;
   secondaryPhoneCapabilities: string[];
   country: string | null;
   state: string | null;
   city: string | null;
   zip: string | null;
   addressLine1: string | null;
   addressLine2: string | null;
   timezone: string | null;

   patientTerritories: PatientWelkinTerritory[];
   careTeamMembers: {
      id: string;
      pointOfContact: boolean;
   }[];
   careTeam: PatientCareTeam[];
   cadence: unknown[];
   pointOfContact: {
      id: string;
      pointOfContact: boolean;
   };
   patientRegion: string | null;
   patientRegionTitle: string | null;
   patientTerritory: string | null;
   patientPrograms: WelkinPatientProgramResponseDto[];
}
