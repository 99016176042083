import { z } from 'zod';
import { uuidSchema } from '../../common';
import { extendApi } from '@anatine/zod-openapi';

export const cognitoLoginSchema = z.object({
   username: extendApi(
      z
         .string()
         .min(1, { message: 'Email is required' })
         .email({ message: 'The email format is incorrect.' }),
      { example: 'dmalnar+test@helloinnerwell.com' },
   ),
   password: extendApi(z.string().min(1, { message: 'Password is required' }), {
      example: 'test123#',
   }),
});

export type CognitoLoginDto = z.infer<typeof cognitoLoginSchema>;
export interface CognitoLoginInterface extends CognitoLoginDto {}

export const adminCognitoLoginSchema = cognitoLoginSchema.extend({
   patientId: uuidSchema,
});

export type AdminCognitoLoginDto = z.infer<typeof adminCognitoLoginSchema>;

export const verifySchema = z.object({
   username: z.string(),
   code: z.string(),
});

export const newPasswordSchema = z.object({
   username: z.string(),
   password: z.string(),
   session: z.string(),
});
