import { PrescriptionStatus, Prisma } from '@innerwell/prisma-app';
import { z } from 'zod';

export const getPrescriptionsParamsSchema = z.object({
   page: z.coerce.number().min(1).default(1),
   pageSize: z.coerce.number().min(1).default(20),
   orderBy: z
      .record(
         z.enum([
            'createdAt',
            'updatedAt',
            'patientName',
            'medicationName',
            'pharmacyName',
            'error',
         ]),
         z.nativeEnum(Prisma.SortOrder),
      )
      .optional(),
   filters: z
      .object({
         patientId: z.string().uuid().optional(),
         cdtId: z.string().uuid().optional(),
         patientName: z.string().optional(),
         clinicianWelkinId: z.string().optional(),
         drugNdcId: z.string().optional(),
         externalPatientId: z.string().optional(),
         statuses: z.array(z.nativeEnum(PrescriptionStatus)).optional(),
      })
      .optional(),
});

export type GetPrescriptionsParamsDto = z.infer<
   typeof getPrescriptionsParamsSchema
>;
