import type { Prisma } from '@innerwell/prisma-app';
import type { GetFindResult } from '@prisma/client/runtime/library';

export const INSURANCE_SELECT = {
   id: true,
   payerId: true,
   name: true,
   internalName: true,
   InsuranceEncounter: {
      select: {
         encounterId: true,
      },
   },
   state: {
      select: {
         id: true,
         name: true,
      },
   },
   InsuranceClinician: {
      select: {
         clinicianId: true,
      },
   },
   order: true,
} as const;

const args = {
   select: INSURANCE_SELECT,
};

export type InsuranceResponseDto = GetFindResult<
   Prisma.$InsurancePayload,
   typeof args,
   unknown
>;
