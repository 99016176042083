import { z } from 'zod';

const chatMetaSchema = z.object({
   nextPageToken: z.string().or(z.null()),
   prevPageToken: z.string().or(z.null()),
   pageSize: z.number().int(),
   found: z.boolean(),
});

export const WelkinChatClientType = {
   User: 'USER',
   Patient: 'PATIENT',
} as const;

export type WelkinChatClientTypeValue =
   (typeof WelkinChatClientType)[keyof typeof WelkinChatClientType];

// const chatClientType = z.custom<WelkinChatClientTypeValue>((data) => {
//    return (
//       _.isString(data) &&
//       _.values(WelkinChatClientType).includes(data as WelkinChatClientTypeValue)
//    );
// });

const patientSchema = z.object({
   clientType: z.literal(WelkinChatClientType.Patient),
   id: z.string().uuid(),
   titleName: z.null(),
   patientFullName: z.string(),
});

const userSchema = z.object({
   clientType: z.literal(WelkinChatClientType.User),
   id: z.string().uuid(),
   titleName: z.string(),
   patientFullName: z.null(),
});

const sourceSchema = z.union([patientSchema, userSchema]);

export const welkinChatMessageSchema = z.object({
   id: z.string(),

   sender: sourceSchema,
   receiver: sourceSchema,
   message: z.string(),
   messageId: z.string(),
   externalId: z.string(),
   createdAt: z.string(),
});

export type WelkinChatMessage = z.infer<typeof welkinChatMessageSchema>;

export const welkinPatientChatMessagesResponseSchema = z.object({
   meta: chatMetaSchema,
   content: z.array(welkinChatMessageSchema),
});

export type WelkinPatientChatMessagesResponseDto = z.infer<
   typeof welkinPatientChatMessagesResponseSchema
>;
